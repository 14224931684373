<template>
  <div
    :style="getElementStyle"
    v-if="!loading && !profilePage && data.properties.filed_content != 'Hide'"
  >
    <div v-if="!isDefalutPos">
      <div style="display: flex">
        <el-col
          :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24"
          v-if="!data.properties.hideLabel"
          style="display: contents"
        >
          <label
            for="text"
            v-if="hasLabel && data.properties.filed_content != 'Hide'"
            :style="getLabelStyles"
            style="margin-right: 5px"
            >{{ label || data.label }}</label
          >
          <span
            v-if="data.validations.required && hasLabel && !isView"
            style="color: red; font-weight: bold; margin-right: 5px"
          >
            *
          </span>
          <span>
            <el-popover
              v-if="data.description"
              placement="top-start"
              title="Title"
              width="200"
              trigger="hover"
              :content="data.description"
              style="margin-right: 5px"
            >
              <i class="el-icon-info" slot="reference"></i>
            </el-popover>
          </span>
        </el-col>
        <el-col :span="showLabel && !isDefalutPos ? 12 : 24">
          <!-- <template v-if="data.input_type=='MULTI_LINE_TEXT'"> -->
          <el-input
            v-if="
              !isView &&
              isList &&
              data.inputType == 'MULTI_LINE_TEXT' &&
              data.properties.filed_content != 'Hide'
            "
            type="textarea"
            :rows="data.validations.rows"
            :placeholder="data.placeholder || data.label"
            v-model="form[data.key]"
            resize="none"
            :readonly="true"
            @change="applyFormRules"
            :disabled="isFromDocument ? false : checkReadonly()"
            :style="{ 'text-align': inputTextAlignment(), ...getStyle }"
          ></el-input>
          <el-input
            v-if="
              !isView &&
              data.inputType == 'MULTI_LINE_TEXT' &&
              !isList &&
              data.input_type != 'RICH_TEXT' &&
              data.properties.filed_content != 'Hide'
            "
            :rows="data.validations.rows"
            type="textarea"
            resize="none"
            :placeholder="data.placeholder || data.label"
            v-model="form[data.key]"
            :disabled="isFromDocument ? false : checkReadonly()"
            @change="applyFormRules"
            :readonly="readonly()"
          >
          </el-input>

          <!-- </template> -->
          <template v-if="data.input_type == 'RICH_TEXT'">
            <TextEditor
              :editorData.sync="form[data.key]"
              v-if="!isView && isView == false && !data.is_entity_variable"
            >
            </TextEditor>
            <p
              v-if="
                (this.$route &&
                  this.$route.name &&
                  this.$route.name == 'ViewTemplate' &&
                  data.is_entity_variable) ||
                (this.$route &&
                  this.$route.name &&
                  this.$route.name == 'PreviewEntityDataStep') ||
                data.is_entity_variable
              "
              style="white-space: pre-line"
              :style="getStyle"
            >
              <span v-html="form[data.key]"></span>
            </p>
          </template>
          <p
            class="sidemenu"
            v-if="isView"
            style="white-space: pre-line"
            :style="getStyle"
          >
            <span v-html="form[data.key]"> </span>
          </p>
        </el-col>
      </div>
    </div>
    <!-- {{data}} -->
    <el-row align="middle" v-else>
      <el-col
        :span="showLabel && !isDefalutPos ? 4 : data.description ? 18 : 20"
        v-if="!data.properties.hideLabel"
        style="display: contents"
        ref="colRef"
        :style="computedStyles"
      >
        <label
          for="text"
          v-if="hasLabel && data.properties.filed_content != 'Hide'"
          :style="getLabelStyles"
          >{{ label || data.label }}</label
        >
        <span
          v-if="data.validations.required && hasLabel && !isView"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
      </el-col>
      <el-col
        v-else
        :span="showLabel ? 4 : data.description ? 20 : 24"
        ref="colRef"
        :style="computedStyles"
      >
        <span
          v-if="hasLabel"
          style="color: white; display: inline-block; height: 20px; opacity: 0"
        ></span>
        <span
          v-if="data.validations.required && hasLabel && !isView"
          style="color: red; font-weight: bold"
        >
          *
        </span>
      </el-col>
      <el-col :span="4" class="button-container">
        <el-button
          v-if="
            data.ai_settings &&
            data.ai_settings.selectedSourceField &&
            data.ai_settings.runOnAction.includes('OnClick')
          "
          :disabled="checkIsGenerateDisabled"
          @click="generateAIForField(data)"
          class="realtym-btn"
        >
          {{ data.ai_settings.realtimeButtonContent || "Generate" }}
        </el-button>
      </el-col>
      <el-col :span="showLabel && !isDefalutPos ? 18 : 20">
        <!-- <p v-if="data.is_global_variable &&  this.$route.name != 'companyProfile'">
          <span>
            {{  form[data.key] }}
          </span>
        </p>    -->
        <el-input
          v-if="
            !isView &&
            isList &&
            data.inputType == 'MULTI_LINE_TEXT' &&
            data.properties.filed_content != 'Hide'
          "
          type="textarea"
          :rows="data.validations.rows"
          :placeholder="data.placeholder || data.label"
          v-model="form[data.key]"
          resize="none"
          :readonly="true"
          @change="applyFormRules"
          :disabled="isFromDocument ? false : checkReadonly()"
          :style="{ 'text-align': inputTextAlignment(), ...getStyle }"
        ></el-input>
        <el-input
          v-if="
            !isView &&
            data.inputType == 'MULTI_LINE_TEXT' &&
            !isList &&
            data.input_type != 'RICH_TEXT' &&
            data.properties.filed_content != 'Hide'
          "
          :rows="data.validations.rows"
          type="textarea"
          resize="none"
          :placeholder="data.placeholder || data.label"
          v-model="form[data.key]"
          :disabled="isFromDocument ? false : checkReadonly()"
          @change="applyFormRules"
          :class="{ 'focus-border-input': highlight }"
          :readonly="readonly()"
          :style="{ 'text-align': inputTextAlignment, ...getStyle }"
        ></el-input>

        <!-- </template> -->
        <template v-if="data.input_type == 'RICH_TEXT' && !isInDataTable">
          <ckeditor
            :config="editorConfigWithOptions"
            v-model="form[data.key]"
            :class="{ 'disabled-div': checkReadonly() }"
            @input="getMentionableFields()"
            v-if="
              (!isView || isView == false) &&
              !data.is_entity_variable &&
              ckeditorConfigCompleted
            "
          >
          </ckeditor>
          <p
            v-if="
              (this.$route &&
                this.$route.name &&
                this.$route.name == 'ViewTemplate' &&
                data.is_entity_variable) ||
              (this.$route &&
                this.$route.name &&
                this.$route.name == 'PreviewEntityDataStep') ||
              data.is_entity_variable
            "
            style="white-space: pre-line"
            :style="getStyle"
          >
            <span v-html="form[data.key]"></span>
          </p>
        </template>
        <template
          v-if="!isView && data.input_type == 'RICH_TEXT' && isInDataTable"
        >
          <div v-if="form[data.key]">
            {{ form[data.key] | truncateHTML(100) }}
            <el-link @click="showCkEditor" type="primary"
              >Edit/ View/ Add</el-link
            >
          </div>
          <div v-else>
            <el-link @click="showCkEditor" type="primary"
              >Edit/ View/ Add</el-link
            >
          </div>
        </template>
        <p
          v-if="isView && data.input_type == 'RICH_TEXT' && isInDataTable"
          style="white-space: normal"
          :style="getStyle"
        >
          <template v-if="form[data.key]">
            {{ form[data.key] | truncateHTML(100) }}
            <el-link @click="showpreview(form[data.key])" type="primary">
              Read more..
            </el-link>
          </template>
          <template v-else>-</template>
        </p>
        <p
          class="sidemenu"
          v-else-if="
            isView && data.input_type == 'MULTI_LINE_TEXT' && isInDataTable
          "
          style="white-space: pre-line"
          :style="getStyle"
        >
          <template v-if="form[data.key]">
            {{ form[data.key] | truncateHTML(100) }}
            <el-link @click="showpreview(form[data.key])" type="primary">
              Read more..
            </el-link>
          </template>
          <template v-else>-</template>
        </p>
        <p
          class="sidemenu"
          v-else-if="
            isView && data.input_type == 'MULTI_LINE_TEXT' && !isInDataTable
          "
          style="white-space: pre-line"
          :style="getStyle"
        >
          <template v-if="form[data.key]">
            {{ form[data.key] }}
          </template>
          <template v-else>-</template>
        </p>
        <span
          class="sidemenu"
          v-else-if="isView && data.input_type == 'RICH_TEXT' && !isInDataTable"
          style="white-space: pre-line"
          :style="getStyle"
        >
          <ckeditor
            :config="editorConfig"
            v-model="form[data.key]"
            :class="{ 'disabled-div': checkReadonly() }"
            v-if="form[data.key]"
            read-only
          >
          </ckeditor>
          <template v-else>-</template>
        </span>
      </el-col>
    </el-row>
    <dialog-component
      :title="data.label"
      :isShowFooter="false"
      :visible="showEditor"
      @before-close="closeCkEditor"
      :containerMinWidth="'67%'"
      :containerMaxWidth="'85%'"
      :containerMinHeight="'60%'"
    >
      <div class="ckeditor-popup">
        <ckeditor
          :config="editorConfigWithOptions"
          v-model="form[data.key]"
          :class="{ 'disabled-div': checkReadonly() }"
          v-if="(!isView || isView == false) && !data.is_entity_variable"
        >
        </ckeditor>
        <p
          v-if="
            (this.$route &&
              this.$route.name &&
              this.$route.name == 'ViewTemplate' &&
              data.is_entity_variable) ||
            (this.$route &&
              this.$route.name &&
              this.$route.name == 'PreviewEntityDataStep') ||
            data.is_entity_variable
          "
          style="white-space: pre-line"
          :style="getStyle"
        >
          <span v-html="form[data.key]"></span>
        </p>
      </div>
    </dialog-component>
    <dialog-component
      :title="data.label"
      :isShowFooter="false"
      :visible="showTextPreview"
      @before-close="closeTextPreview"
      :containerMinWidth="'70%'"
      :containerMinHeight="'50%'"
      :containerMaxWidth="'65%'"
      :containerMaxHeight="'80vh'"
    >
      <div class="text-preview">
        <span v-html="form[data.key]"></span>
      </div>
    </dialog-component>
  </div>
  <div v-else>
    <div v-if="isView && data.properties.filed_content != 'Hide'">
      <span v-html="form[data.key]"></span>
    </div>
    <div v-else>
      <el-input
        v-if="
          data.inputType == 'MULTI_LINE_TEXT' &&
          data.properties.filed_content != 'Hide'
        "
        type="textarea"
        :rows="data.validations.rows"
        :placeholder="data.placeholder || data.label"
        v-model="form[data.key]"
        resize="none"
        @change="applyFormRules"
        :disabled="isFromDocument ? false : checkReadonly()"
        :style="{ 'text-align': inputTextAlignment(), ...getStyle }"
      ></el-input>
    </div>
  </div>
</template>
<script src="https://cdn.ckeditor.com/4.24.0-lts/full-all/ckeditor.js"></script>
<script src="https://cdn.ckeditor.com/4.24.0-lts/standard-all/ckeditor.js"></script>
<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import TextEditor from "../../contentBlocks/TextEditor";
import FieldStylesHelper from "@/mixins/FieldStylesHelper";
import FieldsFormatHelper from "@/mixins/FieldsFormatHelper";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper";
import { mapGetters } from "vuex";
import { ckEditorConfig } from "@/assets/data/multilinetext_ckeditor_config.ts";
export default {
  name: "templates-formComponentsExecute-MultiLineTextExecute",
  mixins: [
    userPermissionsHelper,
    FieldStylesHelper,
    FieldsFormatHelper,
    TemplateBuilderHelper,
  ],
  components: {
    TextEditor,
  },
  props: [
    "index",
    "data",
    "label",
    "form",
    "isInDataTable",
    "isView",
    "hasLabel",
    "isFromDocument",
    "checkIsDisabled",
    "profilePage",
    "fieldsData",
    "isFromView",
    "colorFields",
    "highlight",
  ],
  async mounted() {
    // bus.$on("entity-ur-updated", (data) => {
    //   if (data && data.field && data.field.key == this.form.key) {
    //     this.form.filters = [...data.field.filters];
    //     this.fetchEntitiesDataForTable();
    //   }
    this.loading = true;
    //this.defaultMethod();
    if (this.data.is_global_variable && this.data.global_variable_id) {
      await this.setGlobalVariableData();
    }
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles && this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      // if(this.data.styles.labelStyle == 'right'){
      //   this.isDefalutPos = false;
      // }
    }
    if (this.isInDataTable) {
      this.editorConfigWithOptions.height = 500;
    } else {
      this.editorConfigWithOptions.height = this.data.height - 170;
    }
    this.editorConfigWithOptions.width = this.isInDataTable
      ? ""
      : this.data.width;
    this.editorConfigWithOptions.toolbar = false;

    if (this.data.input_type == "RICH_TEXT") {
      if (this.fieldsData?.length) {
        this.handleRichText();
      }
      if (
        this.data.default_font_type ||
        this.data.default_font_size ||
        this.getCompanyDetails.default_font_size ||
        this.getCompanyDetails.default_font_type
      ) {
        let fontType = this.getFontType();
        let fontSize = this.getFontSize();
        if (fontType) {
          this.editorConfigWithOptions.font_defaultLabel =
            this.getFontLabel(fontType);
        }
        let data = `<span style="font-family:${
          fontType || "Arial, Verdana, sans-serif"
        }; font-size:${fontSize || "12px"}">&shy;</span>`;
        let editorConfigName = this.editorConfigWithOptions.id;
        CKEDITOR.on("instanceReady", function (ev) {
          if (ev.editor?.config?.id == editorConfigName)
            ev.editor.setData(data);
        });
      }
      this.ckeditorConfigCompleted = true;
    }
    this.loading = false;
  },
  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    ...mapGetters("company", ["getCompanyDetails"]),
    getStyle() {
      if (!this.isInDataTable) {
        return `height:${this.data.height}px !important; width:${this.data.width}px !important;`;
      }
      // else {
      //   return `width:${this.data.width}px !important;`;
      // }
    },
    checkIsGenerateDisabled() {
      if (
        this.data?.ai_settings?.selectedSourceField &&
        this.form[this.data.ai_settings.selectedSourceField]
      )
        return false;
      return true;
    },
    computedStyles() {
      const styles = {};

      // Set display to flex
      styles.display = "flex";

      // Set justify-content based on data.styles.labelAlignments
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }

      // Add other dynamic styles if needed

      return styles;
    },
  },
  data() {
    return {
      isList: false,
      hasError: false,
      errorMsg: "",
      showLabel: true,
      isDefalutPosq: true,
      loading: false,
      inputTextAlignmentValue: "left",
      editorConfigWithOptions: this.getEditorConfig(),
      showEditor: false,
      showTextPreview: false,
      editorConfig: {
        // Use spread operator to inherit properties from external config
        ...ckEditorConfig,
        toolbar: [], // Empty toolbar to remove all buttons
        toolbarGroups: [],
        allowedContent: true,
      },
      mentionableFields: [],
      supportedFields: [],
      ckeditorConfigCompleted: false,
    };
  },
  methods: {
    getFontType() {
      if (this.data.default_font_type) {
        return this.data.default_font_type;
      } else if (this.getCompanyDetails.default_font_type) {
        return this.getCompanyDetails.default_font_type;
      }
      return "";
    },
    getFontSize() {
      if (this.data.default_font_size) {
        return this.data.default_font_size;
      } else if (this.getCompanyDetails.default_font_size) {
        return this.getCompanyDetails.default_font_size;
      }
      return "";
    },
    async generateAIForField() {
      try {
        this.$emit("loadComponent", {
          value: true,
          loadingText: "Generating Data...",
        });
        let form = await this.getAIFieldsResponse(
          this.fieldsData,
          [this.data],
          this.form,
          "OnClick"
        );
        this.form[this.data.key] = form[this.data.key];
        this.$emit("loadComponent", { value: false, loadingText: "" });
      } catch (error) {
        this.$emit("loadComponent", { value: false, loadingText: "" });
      }
    },

    getEditorConfig() {
      // Create a deep copy of the base config
      const config = JSON.parse(JSON.stringify(ckEditorConfig));

      // Optionally modify the config based on the index
      config.id = `editor_${this.index}`;

      // Return the modified config
      return config;
    },
    handleRichText() {
      let notSupportedFields = ["IMAGE", "FILE", "PAY_BUTTON", "DATA_TABLE"];
      this.supportedFields = this.fieldsData.filter(
        (e) =>
          !notSupportedFields.includes(e.input_type) &&
          e.key !== this.data.key &&
          e.type != "CONTENT" &&
          e.label
      );
      this.editorConfigWithOptions.mentions = [
        {
          feed: this.dataFeed,
          itemTemplate:
            '<li class="mention-dropdown" data-id="{id}">' +
            '<strong class="label">{label}</strong>' +
            "</li>",
          outputTemplate: (item) => {
            let data;
            if (
              item.inputType == "MULTI_LINE_TEXT" ||
              item.input_type == "RICH_TEXT"
            ) {
              data = this.decodeHtml(item.value);
              return `<span>&nbsp;&nbsp;</span><span>${data}</span><span>&nbsp;&nbsp;</span>`;
            } else {
              return `<span>&nbsp;&nbsp;</span><span>${item.value}</span><span>&nbsp;&nbsp;</span>`;
            }
          },
          minChars: 0,
          convertTo: function (item) {
            return {
              template_key: item.label,
            };
          },
        },
      ];
    },
    dataFeed(opts, callback) {
      var matchProperty = "label",
        data = this.mentionableFields.filter(function (item) {
          return (
            item[matchProperty]
              .toLowerCase()
              .indexOf(opts.query.toLowerCase()) !== -1
          );
        });
      data = data.sort(function (a, b) {
        return a[matchProperty].localeCompare(b[matchProperty], undefined, {
          sensitivity: "accent",
        });
      });
      callback(data);
    },
    getMentionableFields() {
      this.mentionableFields = this.supportedFields.map((e) => {
        return {
          key: e.key,
          label: e.label,
          name: e.label,
          input_type: e.input_type,
          inputType: e.inputType,
          value: this.getFormattedFieldData(e, this.form),
          id: e.key,
        };
      });
    },
    decodeHtml(html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
    closeTextPreview() {
      this.showTextPreview = false;
    },
    showpreview() {
      this.showTextPreview = true;
    },
    showCkEditor() {
      this.showEditor = true;
    },
    closeCkEditor() {
      this.showEditor = false;
    },
    inputTextAlignment() {
      return this.data.styles && this.data.styles.inputTextAlignments
        ? this.data.styles.inputTextAlignments
        : "left"; // Set a default value if not provided
    },
    defaultValueUpdate() {
      if (
        this.data.default_value &&
        this.form &&
        this.data.key &&
        !this.form[this.data.key]
      ) {
        //  this.form[this.data.key] = this.data.default_value;
        this.$set(this.form, this.data.key, this.data.default_value);
      }
    },
    defaultMethod() {
      if (
        !this.form[this.data.key] &&
        this.data.default_mapped_field &&
        this.form[this.data.default_mapped_field]
      ) {
        this.$set(
          this.form,
          this.data.key,
          this.form[this.data.default_mapped_field]
        );
      }
      this.$emit("applyFormRules");
    },
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id,
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );
      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type == "MULTI_LINE_TEXT") {
          this.isList = true;
          this.form[this.data.key] = this.getGlobalVariableById.value;
          this.data.value = this.getGlobalVariableById.value;
        }
      }
    },
    readonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          // if (this.data.styles.labelStyle == "right") {
          //   this.isDefalutPos = false;
          // }
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },
  },
  filters: {
    truncateHTML(text, maxLength) {
      // Remove comments using a regular expression
      var cleanedText = text.replace(/<!--[\s\S]*?-->/g, "");
      // Create a temporary element to parse the cleaned HTML
      var tempElement = document.createElement("div");
      tempElement.innerHTML = cleanedText;
      // Get the text content
      var textContent = tempElement.textContent || tempElement.innerText || "";
      // Truncate the text content
      var truncatedText = textContent.slice(0, maxLength);
      if (textContent.length > maxLength) {
        truncatedText += "...";
      }
      return truncatedText;
    },
  },
  watch: {
    // $props: {
    //   handler() {
    //     this.defaultMethod();
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    "data.styles.inputTextAlignments": {
      handler(newVal) {
        // Whenever inputTextAlignments changes, update the computed property.
        this.inputTextAlignmentValue = newVal;
      },
      immediate: true, // This will trigger the handler immediately when the component is created
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled-div {
  pointer-events: none;
  opacity: 0.5;
}
.focus-border-input ::v-deep .el-textarea__inner {
  border-color: red !important;
}

.multiline {
  height: 100% !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  background: transparent !important;
  border: 1px solid #dcdfe6;
  color: #606266;
  &:focus {
    outline: none !important;
  }
}
.el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 10px 15px;
  line-height: 1.5;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.el-textarea {
  margin-top: 1px !important;
  font-weight: normal;
  @media only screen and (max-width: 767px) {
    font-size: 16px !important;
    width: 100% !important;
  }
}
.sidemenu {
  width: 100%;
  overflow-x: auto;
  overflow-y: auto !important;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  font-weight: normal;
  scrollbar-width: thin;
  //  direction: rtl;
}
.sidemenu::-webkit-scrollbar {
  width: 1em;
  height: 0.5em;
}
.sidemenu::-webkit-scrollbar-horizontal {
  /* Select the down or left scroll button when it's being hovered by the mouse */
  -webkit-border-radius: 10px;
  background: #464444;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #292525;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
.sidemenu::-webkit-scrollbar-button:single-button {
  width: 16px;
  height: 13px;
  background-color: #bbbbbb;
  display: block;
  border-style: solid;
}

.sidemenu::-webkit-scrollbar-button:single-button:horizontal:decrement {
  // border-width: 8px 8px 8px 8px;
  // border-color: transparent transparent #555555 transparent;
}

.sidemenu::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}
/* Down */
.sidemenu::-webkit-scrollbar-button:single-button:horizontal:increment {
  //  border-width: 8px 8px 8px 8px;
  // border-color: #555555 transparent transparent transparent;
}

.sidemenu::-webkit-scrollbar-button:horizontal:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

.ckeditor-popup {
  padding: 20px 0px;
  max-height: 80vh;
}

.text-preview {
  padding: 20px 0px;
  white-space: normal;
  text-align: left !important;
}
.regenerate {
  font-size: large;
  margin-top: 5px;
  margin-left: 5px;
}
.realtym-btn {
  border: none;
  background: none;
  color: var(--primary-color);
}

.el-col-20 {
  width: 100%;
}
</style>
<style>
.MLTE {
}
</style>
